<template>
    <div id="redCardIndex">
        <div class="left_box">
            <div class="prize_list">
               <!--  <van-swipe class="my-swipe" height="40" :autoplay="1000" :loop="true" :vertical="true" :show-indicators="false">
                    <van-swipe-item v-for="(item, index) in mobles" :key="index">
                        <div class="mobles">{{item}}--获得苹果12手机一部</div>
                    </van-swipe-
                    item>
                </van-swipe> -->
                <div class="scroll_box">
                    <ul :class="{scroll: moblesAnimate}">
                        <li v-for="(item, index) in mobles" :key="index">{{item.moble}}--{{item.name}}</li>
                    </ul>
                </div>
            </div>
            <div class="gift_list">
                <div class="card" v-for="(item, index) in activityList" :key="index" @click="groupGift(item.Id)">
                    <span>{{item.GroupName}}</span>
                </div>
            </div>
        </div>
        <div class="right_box">
            <div class="show">
                <van-swipe class="gift_swipe" autoplay="2000" :show-indicators="false">
                    <van-swipe-item v-for="(item, index) in activeGift" :key="index">
                        <div class="swipe_box">
                            <img :src="item.giftImg"/>
                            <p>{{item.giftName}}</p>
                        </div>
                    </van-swipe-item>
                </van-swipe>
                <button @click="jump">
                    <span>立即领奖</span>
                </button>
            </div>
        </div>
    </div>
</template>

<script>
import {getactivities, getgroupgift} from '@/api/redCard';
export default {
    data () {
        return {
            token: '',
            id: '',
            mobles:[],//手机号
            moblesAnimate: false, //中奖列表滚动
            activityList:[], //活动列表
            activeGift: [], //当前选择的礼品
            giftName: [], //所有礼品名称
        }
    },
    mounted () {
        const {token, id} = this.$route.query;
        token && (this.token = token);
        id && (this.id = id);
        this.activities();
        setInterval(this.moblesScroll, 1000);
    },
    methods: {
        /* 随机手机号 */
        randomMoble () {
            this.mobles = [];
            const {giftName} = this;
            setTimeout(() => {
                var prefixArray = new Array("130", "131", "132", "133", "135", "137", "138", "170", "187", "189");
                for (var i=0; i<30; i++) {
                    var prefix = prefixArray[parseInt(10*Math.random())];
                    for (var j = 0; j < 8; j++) {
                        prefix = prefix + Math.floor(Math.random() * 10);
                    }
                    var moble = {
                        moble: `${prefix.substring(0,3)}****${prefix.substring(7)}`,
                        name: giftName[parseInt(giftName.length*Math.random())]
                    };
                    this.mobles.push(moble);
                }
            },100)
            
        },

        moblesScroll () {
            this.moblesAnimate = true;
            setTimeout(() => {
                this.mobles.push(this.mobles[0]);
                this.mobles.shift();
                this.moblesAnimate = false;
            }, 500)
        },

        /* 获取活动列表 */
         async activities () {
            const {token, id, $toast} = this;
            let res = await getactivities({token});
            const {status, data, message} = res;
            if (status == 0) {
                data.data.map(data => {
                    data.id == id && (this.activityList = data.groups);
                });
                this.groupGift(this.activityList[0].Id);
            } else {
                $toast(message);
            }
        },

        /* 获取指定礼品分组的礼品清单 */
        async groupGift (groupId) {
            const {token, $toast} = this;
            this.id = groupId;
            let res = await getgroupgift({token, groupId});
            console.log(res);
            const {status, data, message} = res;
            if (status === 0) {
                this.activeGift = data.data;
                data.data.map(res => {this.giftName.push(res.giftName)});
                this.randomMoble();
            } else {
                $toast(message);
            }
        },


        /* 跳转 */
        jump () {
            const {token, id, $router} = this;
            $router.push({name: 'luckDraw', query:{token, id}});
        }
    }
}
</script>

<style lang="less" src="@/style/drawRedEnvelopes/index.less">

</style>